import React from 'react'
import LogoIcon from "../../images/contacts/logo-image.svg";

const Logo = ({ titleClassName, ...props }) => {
	return (
		<div { ...props }>
			<img width='96px' height='56px' src={LogoIcon} alt='logo company' />
			<h1 className={titleClassName}>СТРОЙТЕХ</h1>
		</div>
	)
}

export default Logo