import React , {useEffect} from 'react'
import Select from "../../../../UIComponents/Select";
import useFeedbackGetOptions from "./components/useGetOptions";
import {useSelector} from "react-redux";

const FeedbackSelectedOrder = ({ controller, type, setFieldValue }) => {
	const options = useFeedbackGetOptions(type)
	const cardSelectedOrder = useSelector(state => state.localOrder.selectedOrder)

	useEffect(() => {
		setFieldValue("selectedOrder", cardSelectedOrder)
	}, [cardSelectedOrder]) //eslint-disable-line

	return (
		<Select
			disabled={!type}
			name="selectedOrder"
			value={controller.value || ''}
			onChange={controller.handleChange}
			onBlur={controller.handleBlur}
			error={controller.touched && Boolean(controller.error)}
			helperText={controller.touched && controller.error}
			defaultLabel={!type ? 'Выберите тип' : ''}
			options={options}
		/>
	)
}

export default React.memo(FeedbackSelectedOrder)