import {styled} from '@mui/material/styles';
import MuiTextField from "@mui/material/TextField";

const TextField = styled(MuiTextField)(() => ({
	"& fieldset": {
		border: 'unset'
	},
	"& .MuiInputLabel-root[data-shrink=true]": {
		transform: "translate(14px, 2px) scale(0.75)"
	},
	"& input": {
		background: "#FEEDD1",
		borderBottom: "2px solid #e5c15b",
		borderRadius: "4px 4px 0 0",
		transition: 'border, background .1s',
		padding: '21.5px 14px 10.5px',
		"&:focus": {
			borderBottom: "2px solid #9C8861"
		},
		"&:hover": {
			background: "#fde8c6"
		}
	},
	".Mui-error input": {
		borderBottom: '2px solid #d32f2f'
	},
	"& label": {
		color: "#49454F"
	} ,
	"& .MuiInputLabel-root.Mui-focused": {
		color: "black"
	},
	"& span": {
		display: "none"
	}
}))

export default TextField