import {styled} from '@mui/material/styles';
import MuiSelect from "@mui/material/Select";

const SelectStyled = styled(MuiSelect)(() => ({
	background: "#FEEDD1",
	transition: 'border, background .1s',
	"&:before": {
		borderBottom: "2px solid #e5c15b",
	},
	"&.Mui-disabled": {
		background: '#ECDCC3'
	},
	"&.Mui-focused": {
		background: "#FEEDD1",
		"&:after": {
			borderBottomColor: "#9C8861"
		}
	},
	"&:hover:not(.Mui-disabled)": {
		background: "#fde8c6",
		"&:hover:before": {
			borderBottom: "2px solid #e5c15b"
		}
	},
	"& .MuiInputLabel-root[data-shrink=true]": {
		transform: "translate(14px, 2px) scale(0.75)"
	},
	".Mui-error:after": {
		borderBottom: '2px solid #d32f2f'
	},
	"& label": {
		color: "#49454F"
	} ,
	"& .MuiInputLabel-root.Mui-focused": {
		color: "black"
	},
	"& span": {
		display: "none"
	}
}))

export default SelectStyled