import './index.scss';
import './fonts/Roboto/stylesheet.css'
import Container from '@mui/material/Container';
import Header from "./components/Header";
import Layout from "./components/Layout";
import SubHeader from "./components/SubHeader";
import Delivery from "./components/Delivery";
import RentVehicle from "./components/RentVehicle";
import Feedback from "./components/Feedback";
import Divider from "./components/UIComponents/Divider";
import Footer from "./components/Footer";
import useWindowSize from "./hooks/useWindowSize";


function App() {
	const windowSize = useWindowSize()

	return (
		windowSize.width > 600
		? <div className="App">
			<Header/>
			<Layout>
				<Container maxWidth="lg">
					<SubHeader />
					<RentVehicle />
					<Delivery />
				</Container>

				<Divider />
				<div className="bg-white">
					<Feedback />
					<Footer />
				</div>
			</Layout>
		</div>
		: <div className="App">
				<Header/>
				<Layout>
					<Container maxWidth="lg">
						<RentVehicle />
						<Delivery />
					</Container>

					<Divider />
					<div className="bg-white">
						<Feedback />
						<Footer />
					</div>
				</Layout>
			</div>
	)
}

export default App;
