import React from 'react';
import styles from './Header.module.scss'
import useWindowSize from "../../hooks/useWindowSize";
import HeaderDesktop from "./layouts/Header.Desktop";
import HeaderMobile from "./layouts/Header.Mobile";

const Header = () => {
    const windowSize = useWindowSize()

    return (
        <header className={styles.wrapper}>
            { windowSize.width > 600
                ? <HeaderDesktop />
                : <HeaderMobile />
            }
        </header>
    )
}

export default Header