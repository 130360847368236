import FeedbackValidationSchema from "./validationSchema";
import { sendOrder } from '../../../redux/actions-exporter'

const FeedbackFormikSettings = (dispatch) => {
	return {
		initialValues: {
			firstName: '' ,
			lastName: '' ,
			phone: '' ,
			orderType: '' ,
			selectedOrder: ''
		} ,
		validationSchema: FeedbackValidationSchema ,
		onSubmit: (values) => {
			dispatch(sendOrder(values))
		}
	}
}

export default FeedbackFormikSettings