import React from 'react'
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MuiRadioGroup from "@mui/material/RadioGroup";
import styles from "../../Feedback/Feedback.module.scss";
import PropTypes from "prop-types";

const RadioGroup = ({ className, name, options, controller }) => {
	return (
		<MuiRadioGroup
			name={name}
			className={className}
			value={controller.value}
			onChange={controller.handleChange}
			onBlur={controller.onBlur}
			error={controller.touched && Boolean(controller.error || undefined)}
		>
			{options.map(option => (
				<FormControlLabel
					className={styles.title}
					key={option.value}
					value={option.value}
					control={<Radio className={styles["input-radio"]} />}
					label={option.label}
				/>
			))}
		</MuiRadioGroup>
	)
}

RadioGroup.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	controller: PropTypes.object.isRequired
}

export default React.memo(RadioGroup)