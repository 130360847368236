import React from 'react'

const Layout = props => {
    return <main className={["main-content", props.className].join(' ')}>
        <div className='main-linear-gradient'/>

        { props.children }
    </main>
}

export default Layout