import React from 'react'
import Container from "@mui/material/Container";
import styles from './Feedback.module.scss'
import {
	FeedbackTextFieldsGroup ,
	FeedbackTypes ,
	FeedbackSelectedOrder ,
	FeedbackButtonSubmit
} from './components/UIComponents'

import FeedbackSwitchAgree from "./components/UIComponents/FeedbackSwitchAgree";
import useFeedbackControllers from "./components/controllers";
import FeedbackModal from "./components/UIComponents/FeedbackModal";

const Feedback = () => {
	const {
		formController ,
		firstNameController ,
		lastNameController ,
		phoneController ,
		typeController ,
		selectedOrderController ,
		agreeController
	} = useFeedbackControllers()

	return (
		<Container id="order">
			<h2 className={styles.title}>Оставить заявку</h2>

			<form className={styles.form__container} onSubmit={formController.handleSubmit}>
				<FeedbackTextFieldsGroup controllers={{
					firstNameController,
					lastNameController,
					phoneController
				}} />
				<FeedbackTypes
					controller={typeController}
					setFieldValue={formController.setFieldValue}
				/>
				<FeedbackSelectedOrder
					type={typeController.value}
					controller={selectedOrderController}
					setFieldValue={formController.setFieldValue}
				/>
				<FeedbackSwitchAgree userAgree={agreeController.setAgree} />
				<FeedbackButtonSubmit agree={agreeController.agree} />
			</form>
			<FeedbackModal />
		</Container>
	)
}

export default Feedback