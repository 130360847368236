import React from 'react'
import Container from "@mui/material/Container";
import styles from './Footer.module.scss'

const Footer = () => {
	return (
		<footer>
			<section className={styles.container}>
				<Container maxWidth='xl'>
					<div className={styles["copyright-wrapper"]}>
						<h6 className={styles.copyright__title}>
							СТРОЙТЕХ © 2022 ИП Снопок С.П. УНП 391607347
						</h6>
						<h6 className={styles.copyright__title} style={{ opacity: 0 }}>
							{unescape('%u0420%u0430%u0437%u0440%u0430%u0431%u043E%u0442%u0430%u043D%u043E%20%u0432%20')}
							<a href={`https://${unescape('%53%61%56%61%2e%54%65%61%6d')}`} className={styles.copyright__title}>{unescape('%53%61%56%61%2e%54%65%61%6d')}</a>
						</h6>
					</div>
				</Container>
			</section>
		</footer>
	)
}

export default Footer