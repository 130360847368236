import { UpdateOrderTypes } from "./updateOrder.types";

export const setOrderType =
	(order) =>
		(dispatch) => {
			dispatch({ type: UpdateOrderTypes.SET_ORDER_TYPE, payload: order });
		};

export const setSelectedOrder =
	(order) =>
		(dispatch) => {
			dispatch({ type: UpdateOrderTypes.SET_SELECTED_ORDER, payload: order });
		};

export const setFeedbackModalVisible =
	(visible) =>
		(dispatch) => {
			dispatch({ type: UpdateOrderTypes.SET_FEEDBACK_MODAL_VISIBLE, payload: visible });
		};