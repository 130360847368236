import Button from "../../../UIComponents/Button";
import React from "react";
import styles from '../../Feedback.module.scss'
import {useSelector} from "react-redux";

const FeedbackButtonSubmit = ({ agree }) => {
	const orderPending = useSelector(state => state.order.orderPending)

	return (
		<div className={styles["submit-wrapper"]}>
			<Button
				variant="text"
				className={styles.button}
				type="submit"
				disabled={!agree || orderPending}
				loading={orderPending}
			>
				Отправить
			</Button>
		</div>
	)
}

export default FeedbackButtonSubmit