import React from 'react'
import Modal from "../../../UIComponents/Modal";
import {useDispatch , useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {setFeedbackModalVisible} from "../../../../redux/actions-exporter";

const FeedbackModal = () => {
	const modalVisible = useSelector(state => state.localOrder.feedbackModalVisible)
	const orderSuccess = useSelector(state => state.order.orderSuccess)
	const dispatch = useDispatch()

	const handleClose = () => dispatch(setFeedbackModalVisible(false))

	return (
		<Modal
			open={modalVisible}
			onClose={handleClose}
			title={orderSuccess ? 'Успешно!' : 'Произошла ошибка...'}
			close
		>
			<Typography>
				{ orderSuccess
					? <>Ваша заявка была успешно отправлена! <br /> Ожидайте, мы вам обязательно перезвоним</>
					: 'Произошла ошибка. Оставить заявку вы можете по следующей почте: ' + process.env.REACT_APP_FEEDBACK_EMAIL}
			</Typography>
		</Modal>
	)
}

export default FeedbackModal