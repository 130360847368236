import React from 'react'
import {Box , Modal as MuiModal , Typography} from "@mui/material";
import PropTypes from "prop-types";
import Button from "../Button";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	paddingBottom: '12px',
	"@media (max-width: 600px)": {
		width: 200,
	}
}

const Modal = ({ open, children, title, close, onClose, ...props }) => {
	return (
		<MuiModal
			{...props}
			open={open}
		>
			<Box sx={style}>
				{title && <Typography variant="h5" component="h2" sx={{ marginBottom: 3 }}>{ title }</Typography>}
				{ children }
				{ close && <Button sx={{ marginTop: 3 }} onClick={onClose}>Закрыть</Button> }
			</Box>
		</MuiModal>
	)
}

Modal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	title: PropTypes.string,
	close: PropTypes.bool
}

export default Modal