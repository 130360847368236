import React from 'react'
import Section from "../UIComponents/Section";
import {useDispatch} from "react-redux";
import {setOrderType , setSelectedOrder} from '../../redux/actions-exporter'
import DeliveryDesktop from "./layouts/Delivery.Desktop";
import useWindowSize from "../../hooks/useWindowSize";
import DeliveryMobile from "./layouts/Delivery.Mobile";

const Delivery = () => {
	const dispatch = useDispatch()
	const windowSize = useWindowSize()

	const handleUpdateOrder = id => {
		dispatch(setOrderType("delivery"))
		dispatch(setSelectedOrder(id))
	}

	return (
		<Section
			id="delivery"
			title="Доставка сыпучих материалов"
			//description="Какое-то описание"
		>
			{
				windowSize.width > 600
					? <DeliveryDesktop
						handleUpdateOrder={handleUpdateOrder}
					/>
					: <DeliveryMobile
						handleUpdateOrder={handleUpdateOrder}
					/>
			}

		</Section>
	)
}

export default Delivery