import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import SwitchStyled from "./сomponents/SwitchStyled";
import styles from './Switch.module.scss'

const Switch = props => {
	return (
		<FormControlLabel
			{...props}
			className={ [styles.container, props.className].join(' ')}
			control={<SwitchStyled />}
			label={props.label}
		/>
	)
}

Switch.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string
}

export default React.memo(Switch)