import React from 'react'
import TextField from "../TextField";
import InputMask from 'react-input-mask'

const PhoneField = ({ value, onChange, onBlur, ...props }) => {

	return (
		<InputMask
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			mask="+375 (99) 999-99-99"
			maskChar=" "
		>
			{() => <TextField { ...props } />}
		</InputMask>
	)
}

export default PhoneField