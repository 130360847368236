import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import data from "../../../data/delivery.json";
import Item from "../../UIComponents/Card";

const DeliveryDesktop = ({handleUpdateOrder}) => {
	return (
		<Grid
			container
			rowSpacing={4}
			columnSpacing={{xs: 1, sm: 3, md: 4}}
			sx={{
				padding: '24px 0 20px'
			}}
		>
			{ data.map(item =>
				<Grid xs={3} key={item.id}>
					<Item
						imageUrl={item.imageUrl}
						name={item.title}
						onClick={() => handleUpdateOrder(item.id)}
					/>
				</Grid>
			) }

		</Grid>
	)
}

export default DeliveryDesktop