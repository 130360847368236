import React from 'react'
import Switch from "../../../UIComponents/Switch";

const FeedbackSwitchAgree = ({ userAgree }) => {
	const handleChange = (e) => userAgree(e.target.checked)

	return (
		<Switch
			label="Согласен на обработку персональных данных"
			name="agree"
			onChange={handleChange}
		/>
	)
}

export default FeedbackSwitchAgree