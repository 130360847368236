import React from 'react'
import Slider from "../../UIComponents/Slider";
import data from "../../../data/rent.json";
import Card from "../../UIComponents/Card";

const RentVehicleDesktop = ({handleUpdateOrder}) => {
	return (
		<Slider
			slidesPerView={3}
			spaceBetween={30}
		>
			{data.map((item, index) =>
				<Card
					key={index}
					imageUrl={item.imageUrl}
					name={item.title}
					onClick={() => handleUpdateOrder(item.id)}
				/>
			)}
		</Slider> 
	)
}

export default RentVehicleDesktop