import React from 'react'
import Section from "../UIComponents/Section";
import {setOrderType , setSelectedOrder} from "../../redux/Local/updateOrder/updateOrder.action";
import {useDispatch} from "react-redux";
import RentVehicleDesktop from "./layouts/RentVehicle.Desktop";
import RentVehicleMobile from "./layouts/RentVehicle.Mobile";
import useWindowSize from "../../hooks/useWindowSize";

const RentVehicle = () => {
	const dispatch = useDispatch()
	const windowSize = useWindowSize()
	const handleUpdateOrder = id => {
		dispatch(setOrderType("rent"))
		dispatch(setSelectedOrder(id))
	}

	return (
		<Section
			id="transport-rental"
			title="Аренда строительной техники"
		>
			{windowSize.width > 600
				? <RentVehicleDesktop
					handleUpdateOrder={handleUpdateOrder}
				/>
				: <RentVehicleMobile
					handleUpdateOrder={handleUpdateOrder}
				/>
			}

		</Section>
	)
}

export default RentVehicle