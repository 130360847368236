import * as yup from "yup";

const FeedbackValidationSchema = yup.object().shape({
	firstName: yup
		.string('Введите ваше имя')
		.required('Поле обязательно для заполнения!'),
	lastName: yup
		.string('Введите ваш email')
		.required('Поле обязательно для заполнения!'),
	phone: yup
		.string('Введите ваш номер телефона')
		.required('Поле обязательно для заполнения!'),
	orderType: yup
		.string('Выберите тип заявки')
		.required('Вы не выбрали тип заявки'),
	selectedOrder: yup
		.string()
		.required('Вы не выбрали товар')
})

export default FeedbackValidationSchema