import { FeedbackActionTypes } from "./feedback.types";
import { axiosInstance } from "../../axios.config";
import {setFeedbackModalVisible} from "../../Local/updateOrder/updateOrder.action";

export const sendOrder =
	({firstName , lastName , phone , selectedOrder}) =>
		(dispatch) => {
			dispatch({ type: FeedbackActionTypes.POST.ORDER_PENDING });

			axiosInstance
				.post("/order", {
					name: firstName,
					lastName,
					phone,
					order: selectedOrder
				})
				.then((res) => {
					dispatch(setFeedbackModalVisible(true))
					dispatch({
						type: FeedbackActionTypes.POST.ORDER_SUCCESS,
						payload: res.data,
					});
				})
				.catch((error) => {
						dispatch(setFeedbackModalVisible(true))
						dispatch({
							type: FeedbackActionTypes.POST.ORDER_ERROR ,
							payload: error.response.data ,
						})
					}
				);
		};