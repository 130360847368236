import React from 'react'
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import SelectStyled from "./components/SelectStyled";
import {FormControl , FormHelperText , InputLabel} from "@mui/material";

const Select = ({ options, defaultLabel, helperText, error, disabled, ...props }) => {
	return (
		<FormControl
			error={error}
			disabled={disabled}
		>
			{ defaultLabel && <InputLabel>{defaultLabel}</InputLabel> }
			<SelectStyled
				{...props}
				variant="filled"
			>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>))}
			</SelectStyled>
			{ helperText && <FormHelperText>{helperText}</FormHelperText> }
		</FormControl>
	)
}

Select.propTypes = {
	label: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	options: PropTypes.array,
	name: PropTypes.string,
}

export default React.memo(Select)