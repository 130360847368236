import React , {useEffect , useMemo} from 'react'
import styles from '../../Feedback.module.scss'
import RadioGroup from "../../../UIComponents/RadioGroup";
import {useSelector} from "react-redux";

const FeedbackTypes = ({ controller, setFieldValue }) => {
	const cardOrderType = useSelector(state => state.localOrder.orderType)

	useEffect(() => {
		setFieldValue("orderType", cardOrderType)
	}, [cardOrderType]) //eslint-disable-line

	const typesList = useMemo(() => ([
		{ label: 'Грузоперевозки', value: 'delivery' },
		{ label: 'Аренда', value: 'rent' }
	]), [])

	return (
		<RadioGroup
			className={styles['radio-container']}
			name="orderType"
			options={typesList}
			controller={controller}
		/>
	)
}

export default React.memo(FeedbackTypes)