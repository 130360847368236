import {useMemo} from "react";
import deliveryData from "../../../../../../data/delivery.json";
import rentData from "../../../../../../data/rent.json";

const deliveryOptions = deliveryData.map(option => ({ value: option.id, label: option.title }))
const rentOptions = rentData.map(option => ({ value: option.id, label: option.title }))

const useFeedbackGetOptions = type => {
	return useMemo(() => {
		switch(type) {
			case 'rent':
				return rentOptions
			case 'delivery':
				return deliveryOptions
			default:
				return [{ value: 'not-selected', label: 'Ничего не выбрано' }]
		}
	}, [type]) //eslint-disable-line
}

export default useFeedbackGetOptions