import {useCallback} from "react";

const ButtonCheckColor = color => useCallback(() => {
		switch (color) {
			case 'inherit':
			case 'primary':
			case 'secondary':
			case 'success':
			case 'error':
			case 'info':
			case 'warning':
				return false
			default:
				return true
		}
	}, [color])

export default ButtonCheckColor