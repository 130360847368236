import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const CardStyled = styled(Paper)(() => ({
  backgroundColor: '#FDAE28',
  padding: '0',
  borderRadius: '12px',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  overflow: 'hidden',
  '&:hover': {
    boxShadow: "0px 1px 2px rgba(255, 255, 255, 0.3), 0px 2px 6px 2px rgba(255, 255, 255, 0.15)"
  }
}));
 
export default CardStyled;