import React from 'react'
import FeedbackTextField from "./FeedbackTextField";

const FeedbackTextFieldsGroup = ({ controllers }) => {
	return (
		<>
			<FeedbackTextField
				name="firstName"
				label="Имя"
				controller={controllers.firstNameController}
			/>
			<FeedbackTextField
				name="lastName"
				label="Фамилия"
				controller={controllers.lastNameController}
			/>
			<FeedbackTextField
				name="phone"
				label="Номер телефона"
				controller={controllers.phoneController}
				isPhoneField={true}
			/>
		</>
	)
}


export default React.memo(FeedbackTextFieldsGroup)