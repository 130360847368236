import React from 'react'
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import styles from './Section.module.scss'

const Section = ({ title, id, description, children }) => {
	return (
		<Container
			maxWidth="lg"
		>
			<h2
				id={id}
				className={styles.title}
				dangerouslySetInnerHTML={{ __html: title }}
			/>
			{description && <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
			{ children }
		</Container>
	)
}

Section.propTypes = {
	title: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]).isRequired,
	description: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
	children: PropTypes.element.isRequired
}

export default Section