import React from 'react'
import styles from "./SocialLink.module.scss";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";

const SocialLink = ({ className, icon, href, iconMaxWidth, ...props }) => {
	return (
		<div
			{ ...props }
			className={[styles.container, className].join(' ')}
		>
			<img className={styles.image} src={icon} style={{ maxWidth: iconMaxWidth }} alt='social-icon'/>
			<Link
				href={href}
				className={styles.link}
				underline='hover'
			>
				{props.children}
			</Link>
		</div>
	)
}

SocialLink.propTypes = {
	className: PropTypes.string ,
	icon: PropTypes.oneOfType([PropTypes.element , PropTypes.string , PropTypes.node]) ,
	href: PropTypes.string,
	iconMaxWidth: PropTypes.string
}

export default SocialLink