import React from 'react'
import CardStyled from "./components/_styled";
import styles from './Card.module.scss'
import PropTypes from "prop-types";
import CardOrderButton from "./components/CardOrderButton";

const Card = ({ imageUrl, name, ButtonOrder, ...props}) => {
	return (
		<a
			{...props}
			href="#order"
			className={styles.container}
		>
			<CardStyled>
				<div className={styles["img-wrapper"]}>
					<img src={imageUrl} alt="order" />
				</div>
				<div className={styles.content}>
					<p className={styles.description}>{name || ''}</p>
					<CardOrderButton ButtonOrder={ButtonOrder} />
				</div>
			</CardStyled>
		</a>
	)
}

Card.propTypes = {
	imageUrl: PropTypes.string,
	name: PropTypes.string,
	ButtonOrder: PropTypes.element
}

export default React.memo(Card);