import {styled} from '@mui/material/styles';
import { Switch as MuiSwitch } from "@mui/material";

const SwitchStyled = styled(MuiSwitch)(() => ({
	zIndex: "auto",
	marginRight: "15px",
	width: 40,
	background: "#FEEDD1",
	border: "2px solid #9C8861",
	borderRadius: "35px",
	padding: 0,
	height: 25,
	".MuiSwitch-input": {
		left: "-60%",
		width: "200%"
	},
	".Mui-checked.MuiSwitch-switchBase": {
		transform: "translateX(16px)",
		color: "#FDAE28",
		"&:hover": {
			color: "#FDAE28"
		}
	},
	".MuiTypography-root": {
		letterSpacing: "0.1px",
		color: "rgba(0, 0, 0, 0.5)",
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "20px",
	},
	".MuiSwitch-thumb": {
		width: 16,
		height: 16
	},
	".MuiSwitch-switchBase": {
		transform: "translateX(0)",
		padding: "2px"
	},
}))

export default SwitchStyled