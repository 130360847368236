import { UpdateOrderTypes } from "./updateOrder.types";

const INITIAL_STATE = {
	orderType: null,
	selectedOrder: null,
	feedbackModalVisible: false
};

const UpdateOrderReducer = (state = INITIAL_STATE, action = {}) => {
	switch(action.type) {
		case UpdateOrderTypes.SET_ORDER_TYPE: {
			return {
				...state,
				orderType: action.payload
			}
		}
		case UpdateOrderTypes.SET_SELECTED_ORDER: {
			return {
				...state,
				selectedOrder: action.payload
			}
		}
		case UpdateOrderTypes.SET_FEEDBACK_MODAL_VISIBLE: {
			return {
				...state,
				feedbackModalVisible: action.payload
			}
		}
		default: {
			return state
		}
	}
}

export default UpdateOrderReducer