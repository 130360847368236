import {useMemo} from "react";

const useMemoizedFormikField = (formik, key) => {
	return useMemo(() => ({
		value: formik.values[key] ,
		handleChange: formik.handleChange ,
		handleBlur: formik.handleBlur,
		touched: formik.touched[key] ,
		error: formik.errors[key]
	}), [formik.values[key], formik.touched[key], formik.errors[key]]) //eslint-disable-line
}

export default useMemoizedFormikField