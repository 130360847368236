import React from 'react'
import Triangle from "../../../images/footer/triangle.svg";
import styles from './Divider.module.scss'

const Divider = () => {
	return (
		<img className={styles.triangle} src={Triangle} alt='triangle'/>
	)
}

export default React.memo(Divider)