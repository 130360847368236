import React from 'react'
import Slider from "../../UIComponents/Slider";
import data from "../../../data/rent.json";
import Card from "../../UIComponents/Card";

const RentVehicleMobile = ({handleUpdateOrder}) => {
	return (
		<Slider
			slidesPerView={"auto"}
			navigation={false}
			variation="contain-items"
			spaceBetween={20}
		>
			{ data.map(item =>
				<Card
					key={item.id}
					imageUrl={item.imageUrl}
					name={item.title}
					onClick={() => handleUpdateOrder(item.id)}
				/>
			) }
		</Slider>
	)
}

export default RentVehicleMobile