import { FeedbackActionTypes } from "./feedback.types";

const INITIAL_STATE = {
	orderPending: false,
	orderSuccess: false,
	orderError: null
};

const FeedbackReducer = (state = INITIAL_STATE, action = {}) => {
	switch(action.type) {
		case FeedbackActionTypes.POST.ORDER_PENDING: {
			return {
				orderPending: true
			}
		}
		case FeedbackActionTypes.POST.ORDER_SUCCESS: {
			return {
				orderSuccess: true,
				orderPending: false,
				orderError: null
			}
		}
		case FeedbackActionTypes.POST.ORDER_ERROR: {
			return {
				orderSuccess: false,
				orderPending: false,
				orderError: action.payload
			}
		}
		default: {
			return state
		}
	}
}

export default FeedbackReducer