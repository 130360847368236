import React from "react";
import styles from "../SubHeader.module.scss";
import PhoneIcon from "../../../images/contacts/phone-icon.svg";
import VkIcon from "../../../images/contacts/vk-icon.svg";
import ViberIcon from "../../../images/contacts/viber-icon.svg";
import SocialLink from "../../UIComponents/SocialLink";

const number = {compact: '+375295198385' , visual: '+375 (29) 519-83-85'}

const SubHeaderContacts = () => {
	return (
		<section className={styles.contacts}>
			<SocialLink
				className={styles.contacts__contact}
				icon={PhoneIcon}
				href={'tel:' + number.compact}
			>{number.visual}</SocialLink>
			<div className={styles.social}>
				<SocialLink
					className={styles.contacts__contact}
					icon={VkIcon}
					href="https://vk.com/public216699586"
				>Мы Вконтакте</SocialLink>
				<SocialLink
					className={styles.contacts__contact}
					icon={ViberIcon}
					href="#"
				>Мы в Viber</SocialLink>
			</div>
		</section>
	)
}

export default SubHeaderContacts