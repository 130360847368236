import React from "react";
import Stack from "@mui/material/Stack";
import styles from "../Header.module.scss";
import Button from "../../UIComponents/Button";

const HeaderDesktop = () => {
	return (
		<Stack className={styles.container} direction="row" spacing={2}>
			<Button
				size="large"
				color="black"
				href="#delivery"
				sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
			>Доставка</Button>
			<Button
				size="large"
				color="black"
				href="#transport-rental"
				sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
			>Аренда</Button>
			<Button
				size="large"
				color="black"
				href="#order"
				sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, width: 'max-content' }}
			>Оставить заявку</Button>
		</Stack>
	)
}

export default HeaderDesktop