import React from 'react'
import MuiButton from "@mui/material/Button";
import ButtonCheckColor from "./components/ButtonCheckColor";
import PropTypes from "prop-types";
import {CircularProgress} from "@mui/material";

const Button = ({ color, loading, ...props }) => {
	const isCustomColor = ButtonCheckColor(color)

	return (
		<MuiButton
			{...props}
			color={!isCustomColor ? color : 'primary'}
			sx={{color: isCustomColor && color , ...props.sx}}
		>
			{ !loading && props.children}
			{ loading && <CircularProgress color="warning" style={{ width: 25, height: 25 }} /> }
		</MuiButton>
	)
}

Button.propTypes = {
	className: PropTypes.string ,
	color: PropTypes.string ,
	disabled: PropTypes.bool ,
	disableElevation: PropTypes.bool ,
	disableFocusRipple: PropTypes.bool ,
	disableRipple: PropTypes.bool ,
	endIcon: PropTypes.node ,
	fullWidth: PropTypes.bool ,
	href: PropTypes.string ,
	size: PropTypes.string ,
	startIcon: PropTypes.node ,
	sx: PropTypes.oneOfType([
		PropTypes.array ,
		PropTypes.object ,
		PropTypes.bool ,
		PropTypes.object ,
	]) ,
	variant: PropTypes.oneOf(['contained' , 'outlined' , 'text']),
	type: PropTypes.oneOf([ 'submit', 'button' ]),
	loading: PropTypes.bool
}


export default React.memo(Button)