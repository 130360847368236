import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import FeedbackFormikSettings from "./FeedbackFormikSettings";
import useMemoizedFormikField from "../../../hooks/useMemoizedFormikField";
import {useState} from "react";

const useFeedbackControllers = () => {
	const dispatch = useDispatch()

	const formController = useFormik(FeedbackFormikSettings(dispatch))
	const firstNameController = useMemoizedFormikField(formController , 'firstName')
	const lastNameController = useMemoizedFormikField(formController , 'lastName')
	const phoneController = useMemoizedFormikField(formController , 'phone')
	const typeController = useMemoizedFormikField(formController , 'orderType')
	const selectedOrderController = useMemoizedFormikField(formController , 'selectedOrder')
	const [agree , setAgree] = useState(false)

	return {
		formController ,
		firstNameController ,
		lastNameController ,
		phoneController ,
		typeController ,
		selectedOrderController ,
		agreeController: {agree , setAgree}
	}
}

export default useFeedbackControllers