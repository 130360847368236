import React from 'react'
import Button from "../../Button";

const CardOrderButton = props => {
	return (
		<Button
			sx={{
				borderRadius: '100px',
				border: '1px solid #d9a52f',
				background: 'rgba(103, 80, 164, 0.08)',
				marginTop: '16px',
				textTransform: 'none',
				padding: '10px 24px',
				"&:hover": {
					border: '1px solid #c38f18'
				}
			}}
			variant="outlined"
			color='inherit'
		>
			{props.ButtonOrder ? <props.ButtonOrder /> : 'Заказать'}
		</Button>
	)
}

export default CardOrderButton