import React from 'react'
import styles from "./SubHeader.module.scss";
import Logo from "../UIComponents/Logo";
import SubHeaderContacts from "./components/SubHeaderContacts";
import {Typography} from "@mui/material";

const SubHeader = () => {
	return (
		<div className={styles.container}>
			<div>
				<Logo className={styles.logo} titleClassName={styles.logo__title} />
				<Typography className={styles.sublogo} >Ежедневно с 8:00 - 22:00</Typography>
			</div>
			<SubHeaderContacts />
		</div>
	)

}

export default SubHeader