import React from 'react'
import TextField from "../../../UIComponents/TextField";
import PropTypes from "prop-types";
import PhoneField from "../../../UIComponents/PhoneField";

const Wrapper = ({ isPhoneField, ...props }) => {
	return isPhoneField
		? <PhoneField {...props} />
		: <TextField {...props} />
}

const FeedbackTextField = ({ name, label, controller, isPhoneField }) => {

	return (
		<Wrapper
			required
			name={name}
			label={label}
			variation="filled"
			value={controller.value}
			onChange={controller.handleChange}
			onBlur={controller.handleBlur}
			error={controller.touched && Boolean(controller.error)}
			helperText={controller.touched && controller.error}
			isPhoneField={isPhoneField}
		/>
	)
}

FeedbackTextField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	controller: PropTypes.object.isRequired,
	isPhoneField: PropTypes.bool
}

export default React.memo(FeedbackTextField)