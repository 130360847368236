import { configureStore, applyMiddleware, compose } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import reducers from "./root-reducer";

let middlewares = [thunk];

let store;

if (process.env.REACT_APP_REDUX_EXTENSION === "true") {
	store = configureStore(
		{ reducer: reducers },
		composeWithDevTools(applyMiddleware(...middlewares))
	);
} else {
	store = configureStore({ reducer: reducers }, compose(applyMiddleware(...middlewares)));
}

export default store;
