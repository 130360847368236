import React, { useRef } from "react";
import {Swiper , SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import styles from './Slider.module.scss'
import PropTypes from "prop-types";

const Slider = ({ reverseDirection, children, navigation, className, style, slideClassName, variation, ...props }) => {
	const navigationPrevRef = useRef(null)
	const navigationNextRef = useRef(null)


	return (
		<div className={styles.container}>
			<Swiper
				{ ...props }
				navigation={ navigation && {
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				modules={navigation && [Navigation]}
				className={className}
				style={{
					padding: '24px 0',
					position: 'relative',
					...style
				}}
				onBeforeInit={(swiper) => {
					if(!navigation) return
					swiper.params.navigation.prevEl = navigationPrevRef.current;
					swiper.params.navigation.nextEl = navigationNextRef.current;
				}}
				initialSlide={ reverseDirection ? children.length : 0}
			>
				{children.map((el, index) => (
					<SwiperSlide
						className={[slideClassName, variation === 'contain-items' && styles.containItemsVariation].join(' ')}
						key={index}
					>
						{el}
					</SwiperSlide>
				))}
			</Swiper>
			{ navigation && <>
					<div ref={navigationPrevRef} className={styles.prev} />
					<div ref={navigationNextRef} className={styles.next} />
				</>
			}
		</div>
	)
}

Slider.propTypes = {
	reverseDirection: PropTypes.bool,
	slideClassName: PropTypes.string,
	navigation: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.string,
	variation: PropTypes.oneOf([ "default", "contain-items" ])
}

export default React.memo(Slider)